import boiler from './boiler.svg';
import boilerService from './boiler-service.svg';
import boilerHeating from './boiler-heating.svg';
import boilerReplacement from './boiler-replacement.svg';
import bulbWarning from './bulb-warning.svg';
import calendar from './calendar.svg';
import checkWhite from './check-white.svg';
import check from './check.svg';
import checkOvo from './check-ovo.svg';
import checkGreenBg from './check-green-bg.svg';
import discount from './discount.svg';
import electrics from './electrics.svg';
import electric from './electric.svg';
import energyAdvice from './energy-advice.svg';
import externalDrain from './external-drain.svg';
import externalDrains from './external-drains.svg';
import gasFlame from './gas-flame.svg';
import gas from './gas.svg';
import help from './help.svg';
import helpline from './helpline.svg';
import info from './info.svg';
import infoDark from './info-dark.svg';
import internalDrain from './internal-drain.svg';
import internalDrains from './internal-drains.svg';
import cross from './menu-cross.svg';
import minus from './minus.svg';
import plus from './plus.svg';
import plumbing from './plumbing.svg';
import radiator from './radiator.svg';
import renew from './renew.svg';
import toilet from './toilet.svg';
import tapsAndToilets from './taps-and-toilets.svg';
import water from './water.svg';
import homeRecoverRedesign from './home-recover-redesign.svg';
import homeRedesign from './home-redesign.svg';
import boilerEngineer from './boiler-engineer.svg';
import waterSupplyPipe from './water-supply-pipe.svg';
import home from './home.svg';
import wrench from './wrench.svg';
import clock from './clock.svg';

interface Icons {
  [name: string]: React.FC<any>;
}

export const icons: Icons = {
  boiler,
  'boiler-service': boilerService,
  'boiler-heating': boilerHeating,
  'boiler-replacement': boilerReplacement,
  'bulb-warning': bulbWarning,
  calendar,
  cross,
  electrics,
  electric,
  'energy-advice': energyAdvice,
  'external-drain': externalDrain,
  'external-drains': externalDrains,
  'gas-flame': gasFlame,
  gas,
  helpline,
  info,
  'info-dark': infoDark,
  radiator,
  'internal-drain': internalDrain,
  'internal-drains': internalDrains,
  renew,
  toilet,
  'taps-and-toilets': tapsAndToilets,
  water,
  'water-supply-pipe': waterSupplyPipe,
  plus,
  plumbing,
  minus,
  check,
  'check-ovo': checkOvo,
  'check-green-bg': checkGreenBg,
  checkWhite,
  discount,
  help,
  homeRecoverRedesign,
  homeRedesign,
  boilerEngineer,
  home,
  wrench,
  clock,
};
