import { ProductName, Promo } from '@entities/enums';
import { PromoConfig, PromoName } from '@entities/types';
import storeService from '@services/StoreService';
import {
  allQ1PromoCodes,
  q1HalfPriceAbsPromoCodes,
  q2HalfPriceAbsPromoCodes,
} from '@constants/promotions';
import {
  beyondPromoFootnotes,
  q2HalfPriceAbsFootnotes,
} from '@constants/footnotes';

export const indirectPromos: PromoConfig[] = [
  {
    order: 1,
    name: PromoName.Q1Offer,
    appliesTo: [
      ProductName.STARTER,
      ProductName.ESSENTIALS,
      ProductName.COMPLETE,
    ],
    endDate: new Date('2024-03-21T00:00:00'),
    startDate: new Date('2024-01-09T00:00Z'),
    promoOptions: {
      type: 'FIRSTMONTHSDISCOUNT',
      duration: 6,
      discountPercentage: 50,
    },
  },
  {
    order: 2,
    validate: () => {
      const store = storeService.load();
      return Boolean(
        !store.isHomeRecover &&
          !store.isBeyond &&
          !store.isAbs &&
          store.isCreditEligible
      );
    },
    name: PromoName.EnergyCreditSVT,
    addon: true,
    appliesTo: [
      ProductName.STARTER,
      ProductName.ESSENTIALS,
      ProductName.COMPLETE,
    ],
    promoOptions: {
      type: 'ENERGYCREDIT',
      amounts: {
        [ProductName.ESSENTIALS]: 100,
        [ProductName.STARTER]: 100,
        [ProductName.COMPLETE]: 100,
      },
    },
  },
  {
    order: 2,
    validate: () => {
      const store = storeService.load();
      return Boolean(
        !store.isHomeRecover &&
          !store.isBeyond &&
          !store.isAbs &&
          store.isCreditEligible &&
          store.creditType === 'SVT'
      );
    },
    name: PromoName.EnergyCreditSVT,
    addon: true,
    appliesTo: [
      ProductName.STARTER,
      ProductName.ESSENTIALS,
      ProductName.COMPLETE,
    ],
    promoOptions: {
      type: 'ENERGYCREDIT',
      amounts: {
        [ProductName.ESSENTIALS]: 100,
        [ProductName.STARTER]: 100,
        [ProductName.COMPLETE]: 100,
      },
    },
  },
  {
    order: 100,
    name: PromoName.Q2AbsOffer,
    startDate: new Date('2024-04-02T00:00Z'),
    endDate: new Date('2024-07-25T23:59:00.000+01:00'),

    appliesTo: [ProductName.ABS],
    footnote: q2HalfPriceAbsFootnotes?.q2HalfPriceAbs,
    promoOptions: {
      type: 'PERCENTDISCOUNT',
      percentage: 50,
    },
  },
  {
    order: 5,
    validate: () => {
      const store = storeService.load();
      return Boolean(
        !store.isHomeRecover &&
          !store.isBeyond &&
          store.isCreditEligible &&
          store.creditType === 'FIXED'
      );
    },
    startDate: new Date('2024-02-28T09:00Z'),
    name: PromoName.EnergyCreditFixed,
    appliesTo: [
      ProductName.STARTER,
      ProductName.ESSENTIALS,
      ProductName.COMPLETE,
    ],
    promoOptions: {
      type: 'ENERGYCREDIT',
      amounts: {
        [ProductName.ESSENTIALS]: 100,
        [ProductName.STARTER]: 100,
        [ProductName.COMPLETE]: 100,
      },
    },
  },
];

const directPromos: PromoConfig[] = [
  {
    order: 1,
    validate: () => {
      const store = storeService.load();
      return !store.isCreditEligible && !store.isHomeRecover;
    },
    endDate: new Date('2024-03-21T00:00:00'),
    startDate: new Date('2024-01-09T00:00Z'),
    name: PromoName.Q1Offer,
    appliesTo: [
      ProductName.STARTER,
      ProductName.ESSENTIALS,
      ProductName.COMPLETE,
    ],
    promoOptions: {
      type: 'FIRSTMONTHSDISCOUNT',
      duration: 8,
      discountPercentage: 50,
      codes: allQ1PromoCodes,
    },
  },
  {
    order: 2,
    startDate: new Date('2024-01-09T00:00Z'),
    name: PromoName.Q1AbsDirectOffer,
    appliesTo: [ProductName.ABS],
    promoOptions: {
      type: 'PERCENTDISCOUNT',
      percentage: 50,
      codes: q1HalfPriceAbsPromoCodes,
    },
  },
  {
    order: 3,
    name: PromoName.AbsBeyond,
    startDate: new Date('2023-11-30T00:00:00'),
    endDate: new Date('2024-04-16T00:00:00'),
    appliesTo: [ProductName.ABS],
    footnote: beyondPromoFootnotes?.beyondPromo,
    promoOptions: {
      type: 'PERCENTDISCOUNT',
      percentage: 71.42,
      codes: [Promo.BEYOND],
    },
  },
  {
    order: 4,
    name: PromoName.BeyondFreeAbs,
    startDate: new Date('2024-04-16T00:00:00'),
    appliesTo: [ProductName.ABS],
    footnote: beyondPromoFootnotes?.beyondFreeAbs,
    promoOptions: {
      type: 'FREE',
      codes: q2HalfPriceAbsPromoCodes,
    },
  },
  {
    order: 5,
    name: PromoName.Beyond12MonthsHalfPrice,
    validate: () => {
      const store = storeService.load();
      return store.isBeyond === true;
    },
    startDate: new Date('2024-05-14T00:00:00'),
    endDate: new Date('2024-09-23T00:00:00'),
    appliesTo: [
      ProductName.STARTER,
      ProductName.ESSENTIALS,
      ProductName.COMPLETE,
    ],
    footnote: beyondPromoFootnotes?.beyond12MonthsHalfPrice,
    promoOptions: {
      type: 'FIRSTMONTHSDISCOUNT',
      duration: 12,
      discountPercentage: 50,
      codes: [Promo.BEYOND12HP],
    },
  },
];

const allPromos: PromoConfig[] = {
  ...indirectPromos,
  ...directPromos,
};

export const getIndirectPromos = () => [...indirectPromos];
export const getDirectPromos = () => [...directPromos];
export const getAllPromos = () => [...allPromos];
