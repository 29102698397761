module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-datadog-rum/gatsby-browser.js'),
      options: {"plugins":[],"applicationId":"29089bb5-e2a2-4b91-86a4-3540f6f391aa","clientToken":"puba323aae679ae72281f086806b81247a9","site":"datadoghq.com","service":"homeplan-ui","sampleRate":100,"enabled":true,"env":"production","trackInteractions":true,"trackResources":true,"trackLongTasks":true,"trackUserInteractions":true,"sessionSampleRate":100,"sessionReplaySampleRate":100,"defaultPrivacyLevel":"mask-user-input"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"homeplan","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/resources/images/ovo-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9dcbb38b49a73bef425aee380a2ff6a1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"457e7322cdf0dcb51030b6a3bafd8805","enableOnDevMode":true,"pageViews":{"/404":"HomePlan - 404 page view"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NZPTBWX","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
