import { Context } from '@datadog/browser-core';
import { datadogLogs, HandlerType, StatusType } from '@datadog/browser-logs';

export const init = () => {
  const dataDogConfig = {
    clientToken: process.env.GATSBY_DD_CLIENT_TOKEN as string,
    service: process.env.GATSBY_DD_SERVICE as string,
    env: process.env.GATSBY_DD_ENV as string,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  };

  datadogLogs.init(dataDogConfig);

  datadogLogs.setGlobalContextProperty('service', dataDogConfig.service);
  datadogLogs.setGlobalContextProperty('source', 'browser');
  datadogLogs.setGlobalContextProperty('env', dataDogConfig.env);
  datadogLogs.setGlobalContextProperty('team', 'growth-ne');

  if (dataDogConfig.env === 'dev' || dataDogConfig.env === 'test') {
    datadogLogs.logger.setHandler(HandlerType.console);
  }
};

export const setGlobalProperty = (key: string, value: string) => {
  datadogLogs.setGlobalContextProperty(key, value);
};

export const log: (
  message: string,
  context?: Context,
  status?: StatusType
) => void = (message, context, status) => {
  datadogLogs.logger.log(message, context, status);
};

export const debug = (message: string, context?: Context) => {
  datadogLogs.logger.log(message, context, StatusType.debug);
};

export const error: (message: string, context?: Context) => void = (
  message,
  context
) => {
  datadogLogs.logger.log(message, context, StatusType.error);
};
