import { Promo } from '@entities/enums';

export const allQ1PromoCodes = [
  Promo.PROMO1,
  Promo.PROMO2,
  Promo.PROMO3,
  Promo.PROMO4,
  Promo.PROMO5,
  Promo.PROMO6,
  Promo.PROMO7,
  Promo.PROMO8,
  Promo.PROMO9,
  Promo.PROMO10,
];

export const q1HalfPriceAbsPromoCodes = [
  Promo.ABS50_1,
  Promo.ABS50_2,
  Promo.ABS50_3,
  Promo.ABS50_4,
  Promo.ABS50_5,
  Promo.ABS50_6,
  Promo.ABS50_7,
  Promo.ABS50_8,
  Promo.ABS50_9,
  Promo.ABS50_10,
];

export const q2HalfPriceAbsPromoCodes = [
  Promo.BEYONDFREEABS,
  Promo.CAREBEYONDFREEABS,
];

/**
 * Valid promo codes for the gift card promotion that
 * can come via the URL
 */
export const urlGiftCardPromos = [Promo.GIFT_CARD_SAVE];
