import cookie from 'js-cookie';
import React, { Children, ReactNode, useEffect, useState } from 'react';

import { useLocalStorage } from '@contexts/LocalStorageContext';
import {
  createInstance,
  OptimizelyExperiment as OptimizelyExperimentOriginal,
  OptimizelyFeature as OptimizelyFeatureOriginal,
  OptimizelyProvider as OptimizelyProviderOriginal,
  useExperiment as useExperimentOriginal,
  useFeature as useFeatureOriginal,
} from '@optimizely/react-sdk';
import StoreService from './StoreService';
import { datadogLogs } from '@datadog/browser-logs';
import { LogLevel } from '@optimizely/js-sdk-logging';

interface FeatureProps {
  children: (isEnabled: boolean, variables?: any) => ReactNode;
  feature: string;
}

interface DynamicUserProps {
  coverType: string;
  excessLevel: number;
  accountId: string;
  testUser: boolean;
}

export enum ExperimentKeys {
  blackFridayBanner = 'bfbannertype',
}

const OptimizelyFeature: React.FC<FeatureProps> = ({ children, feature }) => {
  if (featureMatches(feature)) {
    return children(true);
  }

  return (
    <OptimizelyFeatureOriginal feature={feature}>
      {children}
    </OptimizelyFeatureOriginal>
  ) as any;
};

interface ExperimentProps {
  experiment: string;
  children: React.ReactNode;
}

const OptimizelyExperiment: React.FC<ExperimentProps> = ({
  experiment,
  children,
}) => {
  if (experimentMatches(experiment)) {
    const variation = getVariation();
    const childList = Children.toArray(children) as any;

    if (variation != null) {
      const childToRender = childList.find(
        ({ props }: any) => props.variation === variation
      );

      if (childToRender) {
        return childToRender;
      }
    }

    const defaultChildToRender = childList.find(
      ({ props }: any) => props.default
    );

    if (defaultChildToRender) {
      return defaultChildToRender;
    }

    return null;
  }

  return (
    <OptimizelyExperimentOriginal experiment={experiment}>
      {children}
    </OptimizelyExperimentOriginal>
  );
};

function useFeature(feature: string) {
  const result = useFeatureOriginal(feature);
  if (featureMatches(feature)) {
    const [, ...tail] = result;

    const isDisabled = getFeatureDisabled();
    return [isDisabled ? false : true, ...tail] as const;
  }

  return result;
}

function useExperiment(experiment: string) {
  const result = useExperimentOriginal(experiment);

  if (experimentMatches(experiment)) {
    const [, ...tail] = result;

    return [getVariation(), ...tail] as const;
  }

  return result;
}

type Experiments = { [experimentName: string]: string };

/*
  Used  to return a map of experiment names to variations,

  for use in the mixpanel tracking.
 */
export function useExperiments(experimentNames: ExperimentKeys[]) {
  const resultSet: Experiments = {};

  experimentNames.forEach((experimentName) => {
    const enumIndex = Object.values(ExperimentKeys).indexOf(
      experimentName as unknown as ExperimentKeys
    );
    const key = Object.keys(ExperimentKeys)[enumIndex];
    /// TODO: persist these values into the store so that we dont have to query optimizely every time
    const result = optimizely?.getVariation(experimentName);

    if (result) {
      resultSet[key] = result;
    }
  });
  return resultSet;
}

function featureMatches(feature: string) {
  return getValue('optimizelyFeature') === feature;
}

function getFeatureDisabled(): boolean {
  return getValue('optimizelyFeatureDisabled') === 'true';
}

function experimentMatches(experiment: string) {
  return getValue('optimizelyExperiment') === experiment;
}

function getVariation() {
  return getValue('optimizelyVariation');
}

function getValue(key: string) {
  const urlParams = new URLSearchParams(window.location.search);

  // Cookie used for cypress tests mainly
  return urlParams.get(key) || cookie.get(key);
}

// Export everything first and then override with our own implementations
export * from '@optimizely/react-sdk';
// Ignore exports when no active tests are in progress
// ts-unused-exports:disable-next-line
export { OptimizelyFeature, OptimizelyExperiment, useFeature, useExperiment };

const optimizely =
  typeof window === 'undefined'
    ? null
    : createInstance({
        sdkKey: process.env.OPTIMIZELY_SDK_KEY, // TODO: Find where this should be set
        logLevel: LogLevel.WARNING,
      });

const staticUserAttributes = {
  env: process.env.OPTIMIZELY_ENV_ATTRIBUTE ?? 'not-set',
};

export const OptimizelyProvider: React.FC = ({ children }) => {
  const localStore = useLocalStorage();
  const localStorageStore = localStore.load();
  const sessionStore = StoreService.load();
  // Product would rather the experience be consistent for a given browser, than for a given OVO accountId
  // We could use uuid here, but Math.random is more than sufficient and keeps the bundle size down.
  const optimizelyUserId =
    localStorageStore.optimizelyUserId ?? `id-${Math.random()}`;

  const testUser = localStorageStore.testUser
    ? localStorageStore.testUser
    : false;

  const [dynamicProps, setDynamicProps] = useState<DynamicUserProps>({
    coverType: 'Essential',
    excessLevel: 0,
    accountId: '',
    testUser,
  });

  useEffect(() => {
    localStore.save({ optimizelyUserId, testUser });
    datadogLogs.setGlobalContextProperty('optimizelyUserId', optimizelyUserId);
  }, [optimizelyUserId, testUser, localStore]);

  useEffect(() => {
    if (!sessionStore.selectedCoverType) {
      return;
    }
    setDynamicProps((state) => {
      return {
        ...state,
        coverType: sessionStore.selectedCoverType as string,
        excessLevel: sessionStore.excess ?? 0,
        accountId: sessionStore.accountId ?? '',
        testUser,
      };
    });
  }, [
    sessionStore.selectedCoverType,
    sessionStore.excess,
    sessionStore.accountId,
    testUser,
  ]);

  return (
    <OptimizelyProviderOriginal
      isServerSide={typeof window === 'undefined'}
      optimizely={optimizely!}
      user={{
        id: optimizelyUserId,
        attributes: {
          ...staticUserAttributes,
          ...dynamicProps,
        },
      }}
      timeout={500}
    >
      {children}
    </OptimizelyProviderOriginal>
  );
};
