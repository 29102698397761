import offerTerms from '@resources/offer-terms.pdf';
import privacy from '@resources/privacy-v2.pdf';

// Adding this back temporarily to resolve type checking issue. We need to look at this
// at some point along with BoltonService to see if it's worth keeping
export enum ProductId {
  OVO_BEYOND = '8f09c61e-60e0-4779-87de-77d38ff46d7d',
}

export enum HttpMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
}

export const enum ProductName {
  ESSENTIALS = 'Essentials',
  STARTER = 'Starter',
  COMPLETE = 'Complete',
  ABS = 'Annual Boiler Service',
  HOME_RECOVER = 'Complete + Repair',
}

export enum CoverType {
  ESSENTIAL = 'Essential',
  STARTER = 'Starter',
  COMPLETE = 'Complete',
  ABS = 'Annual Boiler Service',
}

export enum PropertyType {
  FLAT = 'Flat',
  HOUSE = 'House',
}

export enum ExcessAmount {
  ZERO = 'ZERO',
  FIFTY = 'FIFTY',
  SIXTY = 'SIXTY',
  NINETY = 'NINETY',
  NINETY_FIVE = 'NINETY_FIVE',
  NINETY_NINE = 'NINETY_NINE',
}

/**
 * This only exists because the UI uses a radio field that demands
 * a string rather than a boolean as the option.
 */

// I've avoided using strings 'true' and 'false' as this could cause confusion
export enum AutoRenewalOption {
  YES = 'Yes',
  NO = 'No',
}

export enum BoilerBrand {
  ALPHA = 'Alpha',
  ARISTON = 'Ariston',
  ATAG = 'ATAG',
  BAXI_POTTERTON = 'Baxi Potterton',
  BG = 'British Gas',
  GLOWWORM = 'Glow Worm',
  IDEAL = 'Ideal',
  INTERGAS = 'Intergas',
  MAIN = 'Main',
  PRO = 'Pro Combi boilers',
  REMEHA = 'Remeha',
  VAILLANT = 'Vaillant',
  VIESSMANN = 'Viessman',
  VOKERA = 'Vokera',
  WORCESTER = 'Worcester',
  OTHER = 'other',
}

export enum BoilerBrandExclusions {
  EXCLUDED = 'Yes',
  NOTEXCLUDED = 'No',
}

export enum Promo {
  RENEWALS_2022 = 'PROTECT22',
  WINTER_2021 = 'OHPWINTER21',
  ABS_2023 = 'ABS23',
  SIX_MONTH_OFFER = '6MONTHOFFER',
  GIFT_CARD_GIVE = 'GIVE',
  GIFT_CARD_SAVE = 'SAVE',
  GIFT_CARD_SAVE1 = 'SAVE1',
  GIFT_CARD_SAVE2 = 'SAVE2',
  GIFT_CARD_SAVE3 = 'SAVE3',
  GIFT_CARD_SAVE4 = 'SAVE4',
  GIFT_CARD_SAVE5 = 'SAVE5',
  GIFT_CARD_VOUCHER1 = 'VOUCHER1',
  GIFT_CARD_VOUCHER2 = 'VOUCHER2',
  GIFT_CARD_VOUCHER3 = 'VOUCHER3',
  BEYOND = 'BEYOND',
  PROMO1 = 'PROMO1',
  PROMO2 = 'PROMO2',
  PROMO3 = 'PROMO3',
  PROMO4 = 'PROMO4',
  PROMO5 = 'PROMO5',
  PROMO6 = 'PROMO6',
  PROMO7 = 'PROMO7',
  PROMO8 = 'PROMO8',
  PROMO9 = 'PROMO9',
  PROMO10 = 'PROMO10',
  ABS50_1 = 'ABS50-1',
  ABS50_2 = 'ABS50-2',
  ABS50_3 = 'ABS50-3',
  ABS50_4 = 'ABS50-4',
  ABS50_5 = 'ABS50-5',
  ABS50_6 = 'ABS50-6',
  ABS50_7 = 'ABS50-7',
  ABS50_8 = 'ABS50-8',
  ABS50_9 = 'ABS50-9',
  ABS50_10 = 'ABS50-10',
  halfPriceAbs = 'halfPriceAbs',
  BEYONDFREEABS = 'BEYONDFREEABS',
  BEYOND12HP = 'BEYOND12HP',
  CAREBEYONDFREEABS = 'CAREFREEABS',
}

export enum ProductStatus {
  initialized = 'Initialized',
  active = 'Active',
  cancelled = 'Cancelled',
}

export const PHONE_NUMBER = '0330 102 8905';
export const EMAIL_ADDRESS = 'hi@ovoenergy.com';
export const OFFER_TERMS_LINK = offerTerms;
export const PRIVACY_LINK = privacy;
export const WORKING_HOURS = 'Mon-Fri 8am-8pm or Saturday 9am-5pm';

export enum OptimizelyConstants {
  // Pages: HomePlanPageXXX.
  PLANS_PAGE = 'HomePlanPagePlans',

  // Experiments: HomePlanExpXXX.
  EXAMPLE_EXPERIMENT = 'HomePlanExpExample',

  // Events: HomePlanEventXXX.
  SELECT_PLAN_EVENT = 'HomePlanEventSelectPlan',
  SIGNUP_EVENT = 'HomePlanEventSignup',
}

export enum MixpanelEvents {
  SIGNUP_SUCCESS = 'HomePlan - Signup success',
  SIGNUP_ERROR = 'HomePlan - Signup error',
  BAD_BANK_DETAILS_ERROR = 'HomePlan - Bad Bank Details error',
  SELECT_PLAN = 'HomePlan - Selected plan',
  TOGGLE_EXCESS = 'HomePlan - Toggled excess',
  PROMO = 'HomePlan - Promo',
  AUTH_NO_ACCOUNT_ID = 'Homeplan - Error no account id',
  AUTH_ERROR = 'Homeplan - Error with authentication',
  BUTTON_CLICK = 'HomePlan - Button click',
  OPTION_SELECT = 'HomePlan - Option select',
  ERROR = 'HomePlan - Error',
  TOGGLE_AUTO_RENEWAL_ACCORDION = 'HomePlan - Toggle auto-renewal accordion',
  MARKETING_PREFERENCES_SELECTED = 'Marketing preferences selected',
  PROMOCODE_APPLIED = 'HomePlan - Promo code applied',
  USER_PAGE_SCROLL = 'HomePlan - User page scroll',
  ADDRESS_SELECTED = 'HomePlan - Address selected',

  // A/B tests events
  TOGGLE_POLICY_EXCLUSION_ACCORDION = 'Detailed Basket - Toggle Policy Exclusions accordion',
  TOGGLE_CONTRACT_TERMS_ACCORDION = 'Detailed Basket - Toggle Contract Terms accordion',
  TOGGLE_CANCELLATION_POLICY_ACCORDION = 'Detailed Basket - Toggle Cancellation Policy accordion',

  // Hardcoded in gatsby-config.
  PAGE_HOME = 'HomePlan - Home page view',
  PAGE_BOILER = 'HomePlan - Boiler page view',
  PAGE_PAYMENT = 'HomePlan - Payment page view',
  PAGE_SUMMARY = 'HomePlan - Summary page view',
  PAGE_CONFIRMATION = 'HomePlan - Confirmation page view',
  PAGE_PLAN_DETAIL = 'HomePlan - Plan detail page view',
  PAGE_PERSONAL_DETAILS = 'HomePlan - Personal details page view',
  PAGE_HOME_RECOVER_OVERVIEW = 'HomePlan - Home recover overview page view',
  PAGE_REPAIR_DETAILS = 'HomePlan - Repair details page view',
  PAGE_404 = 'HomePlan - 404 page view',
}

export enum Page {
  HOME = 'home',
  DETAILS = 'details',
  BOILER = 'boiler',
  HOME_RECOVER = 'home-recover',
  HOME_RECOVER_DETAILS = 'home-recover-details',
  REPAIR_DETAILS = 'repair-details',
  PAYMENT = 'payment',
  SUMMARY = 'summary',
  CONFIRMATION = 'confirmation',
}

export const enum ErrorMessages {
  REQUIRED = 'This field is required.',
  POSTCODE_INVALID = 'Invalid postcode',
  TELEPHONE_INVALID = 'Please enter a valid UK phone number',
  EMAIL_INVALID = 'Please enter a valid email address',
  SELECT_ADDRESS_REQUIRED = 'Please select an address',
  FORENAME_INVALID = 'First name cannot contain numbers or special characters',
  SURNAME_INVALID = 'Last name cannot contain numbers or special characters',
  FORENAME_INVALID_TOO_SHORT = 'First name must be at least 2 characters long',
  FORENAME_INVALID_TOO_LONG = 'First name must be at most 50 characters long',
  SURNAME_TOO_SHORT = 'Last name must be at least 2 characters long',
  SURNAME_TOO_LONG = 'Last name must be at most 50 characters long',
  PROMO_CODE_NOT_APPLIED = 'Please enter a valid promo code before applying.',
  PROMO_CODE_INVALID = 'Sorry, this isn’t a valid promo code. Please try again.',
  TEXT_MAX_LENGTH = 'Information provided must be at most 225 characters long.',
  ADDRESS_LINE_1_TOO_SHORT = 'Address line 1 must be at least 2 characters long',
  TOWN_TOO_SHORT = 'Town must be at least 2 characters long',
}

export enum RepairIssue {
  BOILER_AND_CENTRAL_HEATING = 'Boiler & central heating',
  INTERNAL_DRAINS_AND_WASTE_PIPES = 'Internal drains & waste pipes',
  PLUMBING = 'Plumbing',
  ELECTRICS = 'Electrics',
}

export enum ProblemType {
  SOMETHING_ELSE = 'Something else',
  NO_CENTRAL_HEATING = 'No central heating',
  NO_HOT_WATER = 'No hot water',
  LEAKING_BOILER = 'Leaking boiler',
  BOILER_PRESSURE = 'Boiler pressure too high or low',
  RADIATOR_NOT_WORKING = 'Radiator not working',
  LEAKING_RADIATOR = 'Leaking radiator',
  FULL_LOSS_OF_POWER = 'Full loss of power',
  CIRCUIT_BREAKER_TRIPPING = 'Circuit breaker tripping',
  LIGHTSWITCH_FAULT = 'Lightswitch Fault',
  SOCKET_FAULT = 'Socket Fault',
  UNCONTROLLABLE_WATER_LEAK = 'Uncontrollable water leak',
  LEAKING_TOILET = 'Leaking toilet',
  FLUSH_NOT_WORKING = 'Flush not working',
  LEAKING_TAP = 'Leaking tap',
  LEAKING_PIPES = 'Leaking pipes',
  BLOCKED_INTERNAL_DRAIN_OR_WASTEPIPE = 'Blocked internal drain or wastepipe',
  LEAKING_INTERNAL_DRAIN_OR_WASTEPIPE = 'Leaking internal drain or wastepipe',
}

export enum Variant {
  STANDARD = 'Standard',
  BEYOND = 'Beyond,',
}

export enum ScrollDepthBreakpoint {
  BREAKPOINT_25 = 0.25,
  BREAKPOINT_50 = 0.5,
  BREAKPOINT_75 = 0.75,
}
