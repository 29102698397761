import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

export interface Footnote {
  id: string;
  content: ReactNode;
}

export interface FootnoteReference extends Footnote {
  index: number;
  supId: string;
  linkId: string;
}

interface FootnoteContext {
  referenced: FootnoteReference[];
  common: Footnote[];
  setReferenced?: React.Dispatch<
    React.SetStateAction<Array<FootnoteReference>>
  >;
  setCommon?: React.Dispatch<React.SetStateAction<Array<Footnote>>>;
}

const defaultContext: FootnoteContext = {
  referenced: [],
  common: [],
};

const Context = createContext(defaultContext);
const Provider: React.FC = ({ children }) => {
  const [referenced, setReferenced] = useState<Array<FootnoteReference>>([]);
  const [common, setCommon] = useState<Array<Footnote>>([]);

  const footnotesContextValue = {
    referenced,
    common,
    setReferenced,
    setCommon,
  };

  return (
    <Context.Provider value={footnotesContextValue}>
      {children}
    </Context.Provider>
  );
};

const Referenced = ({
  footnote,
  children,
}: {
  footnote: Footnote;
  children: (props: {
    index?: number;
    id?: string;
    href?: string;
  }) => JSX.Element;
}) => {
  const { setReferenced, referenced } = useContext(Context);
  const [supId] = useState(() => uuidv4());
  const add = useCallback(
    (footnotes: FootnoteReference[], footnote: Footnote) => {
      const existingFootnote = footnotes.find(
        (item) => item.id === footnote.id
      );

      const footnotesDescIndex = footnotes.sort((a, b) => b.index - a.index);
      const minIndex = footnotesDescIndex.slice(-1)[0]?.index ?? 0;
      const maxIndex = footnotesDescIndex[0]?.index ?? 0;

      const nextIndex = minIndex === 1 ? maxIndex + 1 : 1;

      const footnoteIndex = existingFootnote
        ? existingFootnote.index
        : nextIndex;

      const completeFootnote = {
        ...footnote,
        index: footnoteIndex,
        supId,
        linkId: `footnote-${footnote?.id}`,
      };

      return [...footnotes, completeFootnote];
    },
    [supId]
  );

  const remove = useCallback(
    (footnotes: FootnoteReference[]) => {
      return footnotes.filter((item) => item.supId !== supId);
    },
    [supId]
  );

  useEffect(() => {
    setReferenced!((state) => add(state, footnote));
    return () => {
      setReferenced!(remove);
    };
  }, [footnote, supId, add, remove, setReferenced]);

  const updateCurrent = referenced.find((item) => item.id === footnote.id);

  return children({
    index: updateCurrent?.index,
    id: updateCurrent?.id,
    href: `#${updateCurrent?.linkId}`,
  });
};

const Common = ({ footnote }: { footnote: Footnote }) => {
  const { setCommon } = useContext(Context);

  useEffect(() => {
    setCommon!((state) => [...state, footnote]);
    return () => {
      setCommon!((state) => state.filter((item) => item.id !== footnote.id));
    };
  }, [footnote, setCommon]);

  return <></>;
};

const Yield = ({
  children,
}: {
  children: (props: {
    referenced: FootnoteReference[];
    common: Footnote[];
  }) => JSX.Element;
}) => {
  const { referenced, common } = useContext(Context);

  const referencedClean = referenced
    .filter(
      ({ id: id1 }, index) =>
        referenced.findIndex(({ id: id2 }) => id1 === id2) === index
    )
    .sort((a, b) => a.index - b.index);

  return children({ referenced: referencedClean, common });
};

const useFootnotes = () => {
  const context = useContext(Context);
  if (context !== undefined) return context;
  throw new Error('useFootnotes must be used within a FootnoteProvider');
};

export {
  Provider as FootnotesProvider,
  Referenced as FootnoteReferenced,
  Common as FootnoteCommon,
  Yield as FootnoteYield,
  useFootnotes,
};
