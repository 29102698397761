import AuthService, { Customer } from '@services/AuthService';
import { useQuery } from 'react-query';

export const useAuth = () => {
  const {
    data: user,
    isLoading: loading,
    error,
  } = useQuery<Customer | null>({
    staleTime: 5000,
    cacheTime: 5000,
    queryKey: ['useAuth'],
    queryFn: async () => await AuthService.getCurrentlyLoggedInUser(),
  });

  return {
    user,
    loading,
    error,
  };
};
