import 'isomorphic-fetch';

import { HttpMethod } from '@entities/enums';

type RequestParams<K extends object> = {
  endpoint: string;
  useCredentials: boolean;
  body?: K;
  headers?: object;
};

export default class HttpService {
  static get<T>(params: Omit<RequestParams<object>, 'body'>): Promise<T> {
    return this.request(HttpMethod.Get, params);
  }

  static post<T, K extends object>(params: RequestParams<K>): Promise<T> {
    return this.request(HttpMethod.Post, params);
  }

  static put<T, K extends object>(params: RequestParams<K>): Promise<T> {
    return this.request(HttpMethod.Put, params);
  }

  private static request<T>(
    method: HttpMethod,
    { endpoint, useCredentials, body, headers }: RequestParams<object>
  ): Promise<T> {
    let request: RequestInit = {
      credentials: useCredentials ? 'include' : 'omit',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      },
      method,
    };

    if (body) {
      request = { ...request, body: JSON.stringify(body) };
    }

    return fetch(endpoint, request).then((response: Response) => {
      if (!response.ok) {
        return Promise.reject(response);
      }

      const contentType = response.headers.get('content-type');

      return contentType && contentType.indexOf('application/json') !== -1
        ? response.json()
        : response.text();
    });
  }
}
